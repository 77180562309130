import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Strong, LinkBox, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { FaUserAlt } from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				Wavedropper LTD - Empowering Your Digital Presence with Precision
			</title>
			<meta name={"description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:title"} content={"Wavedropper LTD - Empowering Your Digital Presence with Precision"} />
			<meta property={"og:description"} content={"We craft bespoke web solutions that flow naturally with your business growth."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/anim-optimized.gif?v=2024-07-04T11:36:37.486Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e4858a867c86001f1cafb1/images/favicon.png?v=2024-07-01T17:54:05.854Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.TopNavBar>
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="box3" />
			<Override slot="quarklycommunityKitMenu" />
			<Override slot="link" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride1" />
			<Override slot="image" />
		</Components.TopNavBar>
		<Section
			padding="100px 0 40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-14"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 24px 0px"
				sm-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--primaryBlack"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Our Team
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--primaryBlack"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="justify"
					lg-width="100%"
					sm-text-align="left"
				>
					Choosing Wavedropper means leveraging a team of experts dedicated to creating not just visually appealing websites, but also highly functional and user-centric ones. Our approach ensures that your website not only looks great but also effectively converts visitors into customers. We focus on creating a logical content structure that delivers information in the right order, eliciting the desired emotional response and aligning perfectly with your brand identity. Our meticulous project management guarantees timely delivery and transparent communication, keeping you informed and involved throughout the process.
				</Text>
			</Box>
			<Box min-width="100px" min-height="100px" margin="40px 0px 0px 0px">
				<Box
					width="100%"
					display="grid"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="32px"
					lg-grid-template-columns="1fr"
					lg-grid-gap="48px"
				>
					<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
						<Box>
							<Box align-items="center" display="flex" lg-max-width="720px">
								<Icon
									display="block"
									category="fa"
									icon={FaUserAlt}
									size="20px"
									color="--accent"
									margin="0px 0 0 0"
								/>
								<Text margin="0px 0px 0px 12px" font="--lead">
									Senior Developer
								</Text>
							</Box>
							<Text margin="16px 0px 24px 0px" font="--base" color="--primaryBlack" lg-max-width="720px">
								With over 7 years of commercial experience, our senior developer ensures your website is built with the latest technologies and best practices, offering superior performance and scalability.
							</Text>
						</Box>
						<LinkBox
							flex-direction="row"
							align-items="center"
							justify-content="flex-start"
							color="--primary"
							hover-opacity="0.7"
							transition="--opacityOut"
							display="none"
						>
							<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									LinkedIn
								</Strong>
							</Text>
							<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
						</LinkBox>
					</Box>
					<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
						<Box>
							<Box align-items="center" display="flex" lg-max-width="720px">
								<Icon
									display="block"
									category="fa"
									icon={FaUserAlt}
									size="20px"
									color="--accent"
									margin="0px 0 0 0"
								/>
								<Text margin="0px 0px 0px 12px" font="--lead">
									Project Manager
								</Text>
							</Box>
							<Text margin="16px 0px 24px 0px" font="--base" color="--primaryBlack" lg-max-width="720px">
								Boasting over 3 years of corporate project management and over 10 years of customer success experience, our project manager excels in delivering projects on time, while maintaining clear and transparent communication.
							</Text>
						</Box>
						<LinkBox
							flex-direction="row"
							align-items="center"
							justify-content="flex-start"
							color="--primary"
							hover-opacity="0.7"
							transition="--opacityOut"
							display="none"
						>
							<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									LinkedIn
								</Strong>
							</Text>
							<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
						</LinkBox>
					</Box>
					<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
						<Box>
							<Box align-items="center" display="flex" lg-max-width="720px">
								<Icon
									display="block"
									category="fa"
									icon={FaUserAlt}
									size="20px"
									color="--accent"
									margin="0px 0 0 0"
								/>
								<Text margin="0px 0px 0px 12px" font="--lead">
									Digital Solutions Expert
								</Text>
							</Box>
							<Text margin="16px 0px 24px 0px" font="--base" color="--primaryBlack" lg-max-width="720px">
								With 12 years in digital marketing, and 3 years in development, our expert understands how to craft digital solutions that drive business growth. Their expertise ensures your website not only looks appealing but is also strategically designed to convert visitors.
							</Text>
						</Box>
						<LinkBox
							flex-direction="row"
							align-items="center"
							justify-content="flex-start"
							color="--primary"
							hover-opacity="0.7"
							transition="--opacityOut"
							href="https://www.linkedin.com/in/iamzsolt/"
							target="_blank"
						>
							<Text margin="0px 0px 0px 0px" letter-spacing="0.3px">
								<Strong
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									LinkedIn
								</Strong>
							</Text>
							<Icon category="io" icon={IoIosArrowRoundForward} size="24px" />
						</LinkBox>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14" id="we-are">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://images.unsplash.com/photo-1431576901776-e539bd916ba2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
					srcSet="https://images.unsplash.com/photo-1431576901776-e539bd916ba2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1431576901776-e539bd916ba2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1431576901776-e539bd916ba2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1431576901776-e539bd916ba2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1431576901776-e539bd916ba2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1431576901776-e539bd916ba2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1431576901776-e539bd916ba2?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="#1F2421"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Our Mission
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
					text-align="justify"
				>
					Our mission is to demystify the path to digital success for businesses of all sizes, grounding our approach in the deep analysis of market trends, consumer behavior, and digital footprints. "Patterns of Success, Mapped for Your Business" encapsulates our commitment to crafting bespoke web solutions that enable our clients to not just compete, but to lead in their respective markets. By offering web development through a flexible subscription model, we aim to level the playing field, allowing startups to stand toe-to-toe with established players.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="#1F2421"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Our Vision
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
					text-align="justify"
				>
					Our vision extends beyond providing services; it's about fostering a digital ecosystem where businesses, regardless of their size, have access to the tools and insights needed to thrive. We believe in a future where every digital step a company takes is informed, strategic, and aligned with the unique patterns of success within their industry. Through continuous adaptation and support, we envision our clients navigating the digital landscape with confidence, empowered by solutions that not only meet their current needs but anticipate future growth.
				</Text>
			</Box>
		</Section>
		<Section />
		<Section quarkly-title="Contact">
			<Box
				as="section"
				justify-content="center"
				lg-flex-direction="column"
				quarkly-title="Form-8"
				lg-display="grid"
				lg-grid-template-columns="repeat(2, 1fr)"
				display="grid"
				overflow-y="hidden"
				overflow-x="hidden"
				grid-template-columns="repeat(2, 1fr)"
				md-display="flex"
			>
				<Box
					justify-content="flex-end"
					lg-justify-content="center"
					border-width="0 1px 0 0"
					border-style="solid"
					border-color="--color-grayBg"
					md-border-style="none"
					lg-width="100%"
				>
					<Box
						padding="80px 36px 80px 0px"
						lg-padding="60px 0px 60px 0px"
						align-items="flex-start"
						justify-content="flex-start"
						flex-direction="column"
						lg-max-width="90%"
						margin="0px auto 0px auto"
						md-width="100%"
						display="flex"
					>
						<Text
							text-align="left"
							sm-font="--headline2"
							margin="0px 0px 56px 0px"
							font="--headline2"
							color="--dark"
							sm-align-self="center"
						>
							Contacts
						</Text>
						<Text
							font="--base"
							color="--grey"
							text-transform="uppercase"
							letter-spacing="1px"
							margin="0px 0px 17px 0px"
						>
							Registered address
						</Text>
						<Text margin="0px 0px 48px 0px" font="--lead" color="--dark">
							Wavedropper LTD
							<br />
							<br />
							71-75 Shelton Street,
							<br />
							WC2H 9JQ,
							<br />
							London,
							<br />
							UK
						</Text>
						<Text
							color="--grey"
							text-transform="uppercase"
							letter-spacing="1px"
							margin="0px 0px 17px 0px"
							font="--base"
						>
							e-mail
						</Text>
						<Link
							margin="0px 0px 56px 0px"
							href="mailto:blank?hello@company.com"
							text-align="left"
							color="--dark"
							font="--lead"
							text-decoration-line="initial"
						>
							support@wavedropper.com
						</Link>
						<SocialMedia
							margin="0px 0px 0px 0px"
							facebook="https://www.facebook.com/quarklyapp/"
							twitter="https://twitter.com/quarklyapp"
							instagram="https://instagram.com/instagram"
							display="none"
						>
							<Override
								slot="link"
								margin="0 5px 0 5px"
								width="48px"
								hover-color="--light"
								color="--grey"
								background="--color-darkL2"
								hover-background="--color-dark"
								padding="5x 5px 5px 5px"
								height="48px"
								display="flex"
								align-items="center"
								border-radius="50%"
								justify-content="center"
							/>
							<Override slot="icon-facebook" />
							<Override
								slot="icon"
								color="--light"
								padding="7px 7px 7px 7px"
								border-width="0px"
								size="38px"
								border-radius="50px"
							/>
							<Override slot="link-facebook" margin="0px 5px 0px 0px" />
						</SocialMedia>
					</Box>
				</Box>
				<Box
					lg-justify-content="center"
					display="flex"
					lg-width="100%"
					md-order="-1"
					id="contactform"
				>
					<Box
						width="90%"
						max-width="640px"
						display="flex"
						lg-padding="60px 0px 60px 0px"
						padding="80px 0px 80px 36px"
						justify-content="center"
						lg-max-width="90%"
						flex-direction="column"
						align-items="flex-start"
					>
						<Components.EmailForm>
							<Override slot="SubHeadingText" font="--lead" color="--accent">
								Get in touch with us today to chat about your future website
							</Override>
							<Override slot="HeadingText" color="--primaryBlack" font="--headline2">
								Ready to Get Started?
							</Override>
							<Override slot="text" color="--primaryBlack" display="none" />
							<Override slot="text1" display="none" />
							<Override slot="text2" display="none" />
							<Override
								slot="button"
								background="--color-accent"
								font="--lead"
								border-radius="15px"
								padding="9px 20px 9px 20px"
								focus-box-shadow="none"
							>
								Submit Enquiry
							</Override>
							<Override
								slot="box"
								display="flex"
								justify-items="center"
								align-items="center"
								justify-content="center"
								position="relative"
							/>
							<Override slot="FunPart" margin="16px 0px 32px 0px" font="italic 300 16px/1.5 --fontFamily-googlePoppins" text-align="center" />
						</Components.EmailForm>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="box" />
			<Override slot="box1" />
			<Override slot="wavedropperSignature" />
			<Override slot="box2" />
			<Override slot="box4" />
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="box3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65e4858a867c86001f1cafaf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});